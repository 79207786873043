/* eslint-disable react/prop-types */
import React from 'react';
import { SectionContainer } from '@/components';
import AppStoreIcon from '../img/appstore_download_icon@2x.png';
import GooglePlayIcon from '../img/googleplay_icon@2x.png';
import { LocalStorageService } from '@/services';
import SoulsData from './souls';
import { useParams } from 'react-router-dom';
import { Footer } from '@/components';

import './index.view.less';
import { styled } from '@material-ui/core';

const GameSouls = () => {
    const { id } = useParams();

    const localId = LocalStorageService.get('localId') || 'zh-CN';

    console.log(SoulsData, localId, id)
    return (
        <SectionContainer className="game-detail-souls">
            <div className="view1">
                <picture>
                    {Object.entries(SoulsData.main1).map(([key, value]) => {
                        if (key === 'default') {
                            return <img key={key} src={value[localId]} alt="" />;
                        }
                        return (
                            <source
                                key={key}
                                srcSet={value[localId]}
                                media={`(max-width: ${key}px)`}
                            />
                        );
                    })}
                </picture>
                <div className='downClass'>
                    <a href={SoulsData.downloadUrl.ios} target="_blank"></a>
                    <a href={SoulsData.downloadUrl.android} target="_blank"></a>
                    <a href={SoulsData.downloadUrl.apk} target="_blank"></a>
                </div>
            </div>
            <picture>
                {Object.entries(SoulsData.main2).map(([key, value]) => {
                    if (key === 'default') {
                        return <img key={key} src={value[localId]} alt="" />;
                    }
                    return (
                        <source
                            key={key}
                            srcSet={value[localId]}
                            media={`(max-width: ${key}px)`}
                        />
                    );
                })}
            </picture>
            <picture>
                {Object.entries(SoulsData.main3).map(([key, value]) => {
                    if (key === 'default') {
                        return <img key={key} src={value[localId]} alt="" />;
                    }
                    return (
                        <source
                            key={key}
                            srcSet={value[localId]}
                            media={`(max-width: ${key}px)`}
                        />
                    );
                })}
            </picture>
            <picture>
                {Object.entries(SoulsData.main4).map(([key, value]) => {
                    if (key === 'default') {
                        return <img key={key} src={value[localId]} alt="" />;
                    }
                    return (
                        <source
                            key={key}
                            srcSet={value[localId]}
                            media={`(max-width: ${key}px)`}
                        />
                    );
                })}
            </picture>
            <div className="view5">
                <picture>
                    {Object.entries(SoulsData.main5).map(([key, value]) => {
                        if (key === 'default') {
                            return <img key={key} src={value[localId]} alt="" />;
                        }
                        return (
                            <source
                                key={key}
                                srcSet={value[localId]}
                                media={`(max-width: ${key}px)`}
                            />
                        );
                    })}
                </picture>
                <div className='Wrap'>
                    <div className='downClass'>
                        <a href={SoulsData.downloadUrl.ios} target="_blank"></a>
                        <a href={SoulsData.downloadUrl.android} target="_blank"></a>
                        <a href={SoulsData.downloadUrl.apk} target="_blank"></a>
                    </div>
                    <div className='mediaClass'>
                        <a href={SoulsData.media.Facebook} target="_blank"></a>
                        <a href={SoulsData.media.Ins} target="_blank"></a>
                        <a href={SoulsData.media.Twitter} target="_blank"></a>
                    </div>
                </div>
            </div>
            <Footer />
        </SectionContainer>
        // <SectionContainer className="game-detail-content-1-view">
        //     <div className={ id == 6? 'contentAspin': 'content' }>
        //         <div className="left">
        //             <picture>
        //                 {Object.entries(previewImage).map(([key, value]) => {
        //                     if (key === 'default') {
        //                         return <img key={key} src={value} alt="" />;
        //                     }
        //                     return (
        //                         <source
        //                             key={key}
        //                             srcSet={value}
        //                             media={`(max-width: ${key}px)`}
        //                         />
        //                     );
        //                 })}
        //             </picture>
        //         </div>
        //         <div className="right">
        //             <div className="icon-row">
        //                 <div className="icon-wrapper">
        //                     <img className="icon-logo" src={icon} alt="" />
        //                 </div>
        //                 <div className="icon-logo-text-wrapper">
        //                     <img
        //                         className="icon-logo-text"
        //                         src={logonText[localId]}
        //                         alt=""
        //                     />
        //                 </div>
        //             </div>

        //             <h2 className="title">{title[localId]}</h2>
        //             <p className="desc">{desc[localId]}</p>
        //             <div className="platfom-download">
        //                 <div className="appstore-icon">
        //                     <a href={downloadUrl.ios} target="_blank">
        //                         <img src={AppStoreIcon} alt="" />
        //                     </a>
        //                 </div>
        //                 <div className="google-play-icon">
        //                     <a href={downloadUrl.android} target="_blank">
        //                         <img src={GooglePlayIcon} alt="" />
        //                     </a>
        //                 </div>
        //             </div>
        //             <ul className="google-play-best">
        //                 {googleBest?.map(image => (
        //                     <li className="googleplay-best-icon" key={image}>
        //                         <img src={image} alt="" />
        //                     </li>
        //                 ))}
        //             </ul>
        //         </div>
        //     </div>
        // </SectionContainer>
    );
};

export default GameSouls;
