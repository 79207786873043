import React, { useEffect } from 'react';
import { Header, Footer } from '@/components';
import HomeBannerView from './home-banner/home-banner.view';
import HomeContent00 from './home-content-00/home-content-0.view';
import HomeContent0 from './home-content-0/home-content-0.view';
import HomeContent1 from './home-content-1/home-content-1.view';
import HomeContent2 from './home-content-2/home-content-2.view';
import HomeContent3 from './home-content-3/home-content-3.view';
import HomeContent4 from './home-content-4/home-content-4.view';
import GameDetailData from '../game-detail/game-detail-data';
import { LocalStorageService } from '@/services';

const HomeView = () => {
    const localId = LocalStorageService.get('localId') || 'zh-CN';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <div className="default-view">
                <HomeBannerView />
                <HomeContent00 />
                <HomeContent0 />
                <HomeContent1 />
                <HomeContent2 />
                <HomeContent3 />
                <HomeContent4 />
                <Footer />
            </div>
        </>
    );
};

export default HomeView;
