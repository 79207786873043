import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import { formatMessage, setLanguage } from '@/locales';
import { LocalStorageService } from '@/services';
import LogoImage from './img/logo@2x.png';
import './footer.less';

export const Footer = () => {
    const history = useHistory();
    const { pathname } = useLocation();

    const localId = LocalStorageService.get('localId');
    const [local, setLocal] = useState('zh-CN');

    const setLanguageHandler = newLocalId => {
        setLocal(newLocalId);
        setLanguage(newLocalId);
        history.replace(pathname, {
            localId
        });

        LocalStorageService.set('localId', newLocalId);
    };

    useEffect(() => {
        if (localId && localId !== local) {
            setLocal(localId);
        }
    }, [local, localId]);

    return (
        <footer className="footer">
            {/* <ul className="share-link-list">
                <li className="share-link-item">
                    <a
                        target="_blank"
                        href="https://www.facebook.com/HabbyGames/"
                    >
                        <span
                            role="img"
                            aria-label="facebook"
                            className="anticon anticon-facebook"
                        >
                            <svg
                                className="icon"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="548"
                            >
                                <path
                                    d="M967.4752 0C998.6816 0 1024 25.2928 1024 56.4736v911.0016l-0.1536 4.0704A56.5248 56.5248 0 0 1 967.4752 1024H56.5248A56.5248 56.5248 0 0 1 0 967.5264V56.5248C0 25.3184 25.2928 0 56.4736 0z m-221.696 154.6496c-118.016 0-198.784 71.9872-198.784 204.288v114.0736h-132.992v154.9824h132.992V1024h160V627.9936h133.504l19.9936-154.9824h-153.4976V374.272c0-44.7744 12.4416-75.264 76.6464-75.264h81.3568V160.7424c-14.1568-1.8944-62.72-6.0928-119.2448-6.0928z"
                                    p-id="549"
                                    data-spm-anchor-id="a313x.7781069.1998910419.i0"
                                />
                            </svg>
                        </span>
                    </a>
                </li>
                <li className="share-link-item">
                    <a
                        target="_blank"
                        href="https://www.linkedin.com/company/habbyfun"
                    >
                        <span
                            role="img"
                            aria-label="instagram"
                            className="anticon anticon-instagram"
                        >
                            <svg
                                className="icon"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="568"
                            >
                                <path
                                    d="M950.144 0H73.856A73.856 73.856 0 0 0 0 73.856v876.288A73.856 73.856 0 0 0 73.856 1024h876.288A73.856 73.856 0 0 0 1024 950.144V73.856A73.856 73.856 0 0 0 950.144 0zM363.2128 773.9904h-124.672V398.848h124.672v375.1424zM300.8768 347.648h-0.8192c-41.8304 0-68.8896-28.8-68.8896-64.7936 0-36.8128 27.8784-64.8192 70.528-64.8192 42.6752 0 68.9152 28.0064 69.7344 64.8192 0 35.9936-27.0592 64.7936-70.5536 64.7936z m511.9744 426.368h-124.672v-200.704c0-50.432-18.0736-84.8128-63.1808-84.8128-34.4576 0-54.9632 23.1936-64 45.5936-3.2768 8.0128-4.096 19.2-4.096 30.4384v209.4848h-124.672s1.6384-339.9424 0-375.1424h124.672v53.12c16.5888-25.5744 46.2336-61.9264 112.384-61.9264 82.048 0 143.5648 53.632 143.5648 168.8576v215.0912z"
                                    p-id="569"
                                    data-spm-anchor-id="a313x.7781069.1998910419.i2"
                                />
                            </svg>
                        </span>
                    </a>
                </li>
                <li className="share-link-item">
                    <a
                        target="_blank"
                        href="https://youtube.com/channel/UCxqALA16TciXlUhAozINU-A"
                    >
                        <span
                            role="img"
                            aria-label="youtube"
                            className="anticon anticon-youtube"
                        >
                            <svg
                                className="icon"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 1462 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="552"
                            >
                                <path
                                    d="M1432.722286 160.241371a183.266743 183.266743 0 0 0-128.936229-128.906971C1189.302857 0 731.428571 0 731.428571 0S273.525029 0 159.041829 30.134857c-61.44 16.852114-112.054857 67.466971-128.906972 130.106514C0 274.666057 0 512 0 512s0 238.533486 30.134857 351.758629a183.296 183.296 0 0 0 128.936229 128.906971C274.724571 1024 731.428571 1024 731.428571 1024s457.874286 0 572.357486-30.134857a183.266743 183.266743 0 0 0 128.936229-128.877714C1462.857143 750.533486 1462.857143 513.170286 1462.857143 513.170286s1.199543-238.533486-30.134857-352.958172z"
                                    p-id="553"
                                    data-spm-anchor-id="a313x.7781069.1998910419.i3"
                                />
                                <path
                                    d="M585.610971 731.253029L966.392686 512l-380.781715-219.253029z"
                                    fill="#000000"
                                    p-id="554"
                                />
                            </svg>
                        </span>
                    </a>
                </li>
            </ul> */}
            <ul className="website-info-list">
                <li className="website-info-item">
                    <a className="logo-wrapper" to="/">
                        <img src={LogoImage} alt="" />
                    </a>
                </li>
                <li className="website-info-item">
                    <a href="https://www.lezuan.net/server.html" target="_blank">{formatMessage('footer.link.termsOfService')}</a>
                </li>
                <li className="website-info-item">
                    <a href="https://www.lezuan.net/gamePrivacy.html" target="_blank">{formatMessage('footer.link.privacyPolicy')}</a>
                </li>
                <li className="website-info-item">
                    <a href="mailto:support@habby.com" target="_blank">
                        {formatMessage('footer.link.support')}
                    </a>
                </li>
                <li className="website-info-item">
                    <a href="mailto:business@habby.fun"  target="_blank">
                        {formatMessage('footer.link.contacts')}
                    </a>
                </li>
                <li className="website-info-item toggle-local">
                    <span
                        className={classNames({
                            active: local === 'en-US'
                        })}
                        onClick={() => {
                            setLanguageHandler('en-US');
                        }}
                    >
                        EN
                    </span>
                    <span>/</span>
                    <span
                        className={classNames({
                            active: local === 'zh-CN'
                        })}
                        onClick={() => {
                            setLanguageHandler('zh-CN');
                        }}
                    >
                        中文
                    </span>
                </li>
            </ul>
            <p className="copyright">{formatMessage('footer.copyright')}</p>
            <p className="copyright"> <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备19032146号</a></p>
        </footer>
    );
};
