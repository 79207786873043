import React from 'react';
import { Link } from 'react-router-dom';
import { Button, SectionContainer } from '@/components';
import { formatMessage } from '@/locales';
import GooglePlayImage from './img/google-play@2x.png';
import './home-content-0.view.less';
import { LocalStorageService } from '@/services';
import newImage from './img/new.png';

const HomeContent0View = () => {
    const localId = LocalStorageService.get('localId') || 'zh-CN';
    const logonText = {
        'en-US': require('./img/logo_text@2x.png'),
        'zh-CN': require('./img/logo_text_cn@2x.png')
    };

    return (
        <SectionContainer
            className="home-content-2-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('./img/home_page_archero_background@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('./img/background.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="contents">
                <h1 className="title">
                    <span className="logo-wrapper">
                        <img src={logonText[localId]} alt="" />
                    </span>
                    <div className="new">
                        <img src={newImage} alt="" />
                    </div>
                    {/* <span className="text">
                        {formatMessage({ id: 'home.content0.name' })}
                    </span> */}
                </h1>
                {/* <div className="google-play-wrapper">
                    <img src={GooglePlayImage} alt="" />
                </div> */}
                <p className="desc">
                    {formatMessage({ id: 'home.content0.description' })}
                </p>
                <a
                    className="abutBtnIos"
                    target="_target"
                    href="https://apps.apple.com/sg/app/survivor-io/id1528941310"
                >
                    <img
                        className="jump-image"
                        src={require('./img/store.png')}
                        alt=""
                    />
                </a>
                <a
                    className="abutBtnA"
                    target="_target"
                    href="https://play.google.com/store/apps/details?id=com.dxx.firenow"
                >
                    <img
                        className="jump-image"
                        src={require('./img/play.png')}
                        alt=""
                    />
                </a>
                <Link to="/game/detail/survivor">
                    {localId === 'en-US' ? (
                        <Button className="abut sss">
                            {formatMessage({ id: 'home.content0.buttonText' })}
                        </Button>
                    ) : (
                        <Button className="abut">
                            {formatMessage({ id: 'home.content0.buttonText' })}
                        </Button>
                    )}
                    {/* <Button className="abut">
                        {formatMessage({ id: 'home.content0.buttonText' })}
                    </Button> */}
                </Link>
            </div>
        </SectionContainer>
    );
};

export default HomeContent0View;
